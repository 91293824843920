var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min-h-full relative max-h-full flex flex-col text-base-content"},[_c('sub-header',{staticClass:"mb-4",attrs:{"customCrumbLists":_vm.customBreadCrumbs,"showBtn":_vm.showCreateButton,"buttonText":"Add New","backButton":true,"backRedirectionUrl":_vm.backRedirectionUrl,"handleClick":_vm.handleCreate}}),_c('div',{staticClass:"justify-between flex-grow max-h-full overflow-y-auto"},[_c('div',{staticClass:"flex h-full gap-4 flex-1 overflow-y-auto"},[(_vm.currentComponent?.name != 'createTriggerActions')?_c('div',{staticClass:"w-2/12 rounded-lg self-start bg-white shadow flex flex-col gap-x-4 overflow-y-auto scroll-bar"},_vm._l((_vm.navigation),function(nav,idx){return _c('div',{key:idx,staticClass:"px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3 cursor-pointer",class:{
            'bg-primary-300 hover:bg-primary-200 text-base-content-800':
              _vm.currentTab === nav.nav,
            'hover:bg-gray-100 hover:text-base-content-600':
              _vm.currentTab !== nav.nav,
            'border-t border-gray-400 border-0': idx != 0,
            'cursor-not-allowed pointer-events-none bg-gray-100 border-gray-300 text-gray-700':
              nav.disabled,
          },on:{"click":function($event){return _vm.handleCurrentTab(nav)}}},[_c('font-awesome-icon',{attrs:{"icon":[nav.iconPrefix || 'fa', nav.icon]}}),_vm._v(" "+_vm._s(nav.title)+" ")],1)}),0):_vm._e(),(_vm.currentTab)?_c('div',{class:_vm.isTriggersRoute
            ? 'flex-1 relative'
            : 'flex-1 relative bg-card-bg rounded-md shadow'},[(!_vm.isTriggersRoute)?_c('div',{staticClass:"px-4 lg:px-5 py-3 flex gap-3 items-center bg-primary-300 border-b border-gray-200 text-base-content-600 text-lg font-medium",style:(`max-height: 20vh;`)},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.TabLabel)+" ")])]):_vm._e(),_c('div',{class:_vm.isTriggersRoute ? '' : 'p-3 flex pb-4'},[_c(_vm.currentComponent,{tag:"component",on:{"saveAndNext":_vm.handleSaveAndNext}})],1)]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }