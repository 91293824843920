<template>
  <div class="flex-1 flex flex-col max-h-full">
    <sub-header
      :showBtn="showCreateButton"
      :handleClick="createCaseWorkflow"
      buttonText="Create Workflow"
    />
    <div
      class="flex flex-1 flex-col flex-grow max-w-full overflow-auto scroll-bar"
    >
      <v-table
        ref="vueGoodTable"
        id="caseStatusTable"
        :mode="'records'"
        :columns="columns"
        :rows="filteredRows"
        @handleClone="handleCloneWorkflow($event)"
        :isLoading="listLoader"
        :pagination-options="paginationOptions"
        :total-rows="getTotalRows"
        @column-filter="onColumnFilters($event)"
        @page-change="onPageChange"
        :filters="filterTerm"
        @filters-clear="clearFilters"
        :filterOptions="filterOptions"
        sortingEnabled
      >
        <template #actions="customData">
          <div class="flex justify-around">
            <!-- <button
              class="h-6 w-4 mx-2 mt-1 disabled:opacity-50"
              @click="handleCloneWorkflow(customData.customData)"
              v-tippy
              :content="'Clone'"
            >
              <font-awesome-icon
                icon="clone"
                class="text-blue-600 text-lg fa-2x"
                :class="
                  customData.customData
                    ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                    : 'text-gray-500'
                "
              />
            </button> -->
            <router-link
              :to="{
                name: 'Workflow Engine Details',
                params: {
                  workflowId: customData?.customData?.id,
                  action: 'edit',
                  type: 'case',
                },
              }"
            >
              <button
                class="h-8 w-4 disabled:opacity-50 mx-2"
                v-tippy
                :content="'Edit'"
                :disabled="!checkPermission('workflow.edit')"
              >
                <font-awesome-icon
                  icon="edit"
                  class="text-blue-600 text-lg fa-3x"
                  :class="
                    customData.customData
                      ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                      : 'text-gray-500'
                  "
                />
              </button>
            </router-link>
            <router-link
              :to="{
                name: 'Workflow Engine Details',
                params: {
                  workflowId: customData?.customData?.id,
                  action: 'view',
                  type: 'case',
                },
              }"
            >
              <button
                class="h-8 w-4 disabled:opacity-50 mx-2"
                v-tippy
                :content="'View'"
              >
                <font-awesome-icon
                  icon="eye"
                  class="text-blue-600 text-lg fa-3x"
                  :class="
                    customData.customData
                      ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                      : 'text-gray-500'
                  "
                />
              </button>
            </router-link>
          </div>
        </template>
      </v-table>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import Table from "@shared/dashboard-components/table-large";
import { fetchStatusList, cloneWorkflow } from "@shared/workflow/service.js";
import { FormatDateNew } from "@/plugins/functions";
import { checkPermission } from "@shared/utils/functions";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";
// import vue from "vue";

// import  Vue  from "vue";

export default {
  name: "workflowDetail",
  components: {
    SubHeader,
    "v-table": Table,
  },
  data() {
    return {
      columns: [
        {
          label: "Action",
          field: "actions",
          config: { filter: false, type: "actions" },
          sortable: false,
          tdClass: "truncate w-10",
        },
        {
          label: "Workflow Name",
          field: "name",
          sortable: true,
          config: { filter: true, type: "select", showToolTip: true},
          tdClass: "truncate max-w-sm",
        },
        {
          label: "Description",
          field: "description",
          sortable: true,
          //   query_key: "check_type",
          config: { filter: true, type: "select", showToolTip: true },
          tdClass: "truncate max-w-sm",
        },
        // {
        //   label: "Package Name",
        //   field: "packageName",
        //   sortable: true,
        //   config: { filter: true, type: "select" },
        //   tdClass: "truncate max-w-sm",
        // },
        {
          label: "Created At",
          field: "created_at",
          sortable: true,
          config: { filter: true, type: "source" },
          tdClass: "truncate max-w-sm",
        },
        {
          label: "Last Updated By",
          field: "modified_by",
          sortable: true,
          //   config: { filter: false, type: "show_info" },
          tdClass: "truncate max-w-sm",
        },
        {
          label: "Last Updated At",
          field: "modified_at",
          sortable: true,
          config: { filter: true, type: "select" },
          tdClass: "truncate max-w-sm",
        },
      ],
      isLoading: false,
      listLoader: false,
      statusList: [],
      paginationOptions: {
        enabled: true,
        mode: "records", // Ensure this is set to 'records'
        perPage: 10, // Set the number of records per page
        setCurrentPage: 1,
        dropdownAllowAll: false,
      },
      totalRows: 0,
      filterTerm: {},
    };
  },
  mounted() {
    this.getStatusList();
  },
  computed: {
    filteredRows() {
      let filteredRows = this.statusList;
      Object.keys(this.filterTerm).forEach((key) => {
        if (this.filterTerm[key]) {
          filteredRows = filteredRows.filter((row) =>
            row[key]
              ?.toString()
              .toLowerCase()
              .includes(this.filterTerm[key]?.name?.toString().toLowerCase())
          );
        }
      });
      return filteredRows;
    },
    showCreateButton() {
      return checkPermission("workflow.create");
    },
    getTotalRows() {
      return this.filteredRows.length;
    },
    filterOptions() {
      let result = this.getFilterOptions(this.columns, this.statusList);
      return result;
    },
  },
  methods: {
    checkPermission,
    FormatDateNew,
    getFilterOptions(columns, rows) {
      return columns.reduce((result, curr) => {
        result[curr.field] = rows
          .filter((row) => row[curr.field])
          .map((row) => row[curr.field])
          .filter(onlyUnique)
          .map((el) => ({ id: uuid.v4(), name: el }));
        return result;
      }, {});
    },
    async getStatusList() {
      try {
        this.listLoader = true;
        const response = await fetchStatusList("case");
        if (response.data.status) {
          this.statusList = response?.data?.data || [];
          this.statusList = this.statusList?.map((item) => {
            if (item.created_at || item.modified_at) {
              item.created_at = FormatDateNew(new Date(item.created_at));
              item.modified_at = FormatDateNew(new Date(item.modified_at));
              return item;
            }
          });
          this.totalRows = this.statusList.length;
          this.listLoader = false;
        }
      } catch (err) {
        console.log("err", err);
        this.listLoader = false;
        this.$toast.error(
          err?.response?.data?.detail || "Something went wrong"
        );
      }
    },
    async handleCloneWorkflow(workflow) {
      try {
        let payload = {
          workflow_id: workflow.id,
        };
        const response = await cloneWorkflow("case", payload);
        if (response.status) {
          this.$toast.success(
            response.data.status_message || "Clone successfully"
          );
        }
        await this.getStatusList();
      } catch (err) {
        console.error(err);
        this.$toast.error(
          err?.response?.data?.detail || "Something went wrong"
        );
      }
    },
    createCaseWorkflow() {
      this.$router.push({
        name: "Workflow Engine Details",
        params: { action: "create", type: "case" },
      });
      return;
    },
    onColumnFilters({ columnFilters }) {
      this.filterTerm = columnFilters;
      this.paginationOptions = { ...this.paginationOptions, setCurrentPage: 1 };
    },
    clearFilters() {
      this.filterTerm = {};
      this.getStatusList();
    },
    onPageChange(evt) {
      this.paginationOptions.setCurrentPage = evt.currentPage;
    },
  },
};
</script>
